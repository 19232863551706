.main{
    margin-top: 4rem;
    padding: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.heading{
    font-family: 'Ubuntu', sans-serif;
    margin-bottom: 1rem;
    margin-top: 6rem;
    color: #15458C;
    text-align: center;
}

.finance{
    margin-top: 3rem;
    font-family: 'Nunito', sans-serif;   
}

@media only screen and (max-width: 28.44em) {
    .finance{
        font-size: 3.5rem;
    }

}

.serviceList{
    font-family: 'Nunito', sans-serif;
}

