.modal{
    height: 90vh;
    width: 100%;
    padding: 3rem;
    background-color: rgba(14, 30, 54, 0.45);
    position: absolute;
    z-index: 10;

}



.homeHeader{
    background-size: 100% 120%; 
    background-repeat: no-repeat; 
    width: 100%;
    height: 90vh;
    padding: 3rem;
}
@media only screen and (max-width: 768px) {
    .homeHeader {
      padding: 0;
    }
}

.headerImg{
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.homeBgImg1{
    background-image: url(../../media/homePageBgPic-1.jpg);
    transition: all 1000ms linear;
}

.homeBgImg2{
    background-image: url(../../media/homePageBgPic-2.jpg);
    transition: all 1000ms linear;

}

.homeBgImg3{
    background-image: url(../../media/homePageBgPic-3.jpg);
    transition: all 1000ms linear;
}


.headerInscription{
    color: white;
    width: 35%;
    margin-top: 18%;
}



@media only screen and (max-width: 40.63em) {
    .headerInscription {
      width: 80%;
      margin-top: 40%;
    }
}

@media only screen and (max-width: 25em) {
    .headerInscription {
      width: 80%;
      margin-top: 66%;
    }
  }

.headerInscription h1{
    margin-bottom: 2rem;
    color: white;
}

.heading{
    animation: moveRight 500ms linear;
}

.details{
    animation: moveUp 500ms linear;
}

.Hmain{
    padding: 3rem
}

@media only screen and (max-width: 28.44em) {
    .Hmain {
        padding-left: 0;
        padding-right: 0;
    }
}

.section{
    display: flex;
    margin-bottom: 3rem;
    gap: 3rem;

}


@media only screen and (max-width: 62.5em) {
    .section {
      display: block;
    }
}

.pic{
    height: 50rem;
    width: 50%;
}

@media only screen and (max-width: 62.5em) {
    .pic {
        width: 100%;
    }
}

@media only screen and (max-width: 28.44em) {
    .pic {
        height: 70rem;
    }
}

.pic img{
    height: 100%;
    width: 100%;
}

.text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    height: 50rem;
    background-color: whitesmoke;
    width: 50%;
}

@media only screen and (max-width: 62.5em) {
    .text {
        width: 100%;
    }
}

@media only screen and (max-width: 28.44em) {
    .text {
        height: 70rem;
    }
}



.left{
    transform: translateX(-20rem);
    transition: transform 500ms linear; 
    opacity: 0;
    
}

@media only screen and (max-width: 28.44em) {
    .left {
        margin-bottom: 4rem;
    }
}



.leftAnim{

    transform: translateX(0);
    transition: transform 500ms linear;
    opacity: 1;

}

.right{
    transform: translateX(20rem);
    transition: transform 500ms linear; 
    opacity: 0;
    width: 0%;
}


@media only screen and (max-width: 62.5em) {
    .right {
        width: 0%;
    }
}

.rightAnim{
    transform: translateX(0);
    transition: transform 500ms linear; 
    opacity: 1; 
     
    width: 50%;
} 

@media only screen and (max-width: 62.5em) {
    .rightAnim {
        width: 100%;
    }
}

.clientLogoSection{
    
    margin-top: 5rem;
    margin-bottom: 10rem;
}

.clientLogo{
    display: flex;
    justify-content: space-around;
    /* background-color: #15458C; */
}
.eroton{
    background-color: #15458C;
}

.logoBox{
    height: 10rem;
    margin: .5rem;
    width: 15%;
    
}
.logoGroup{
    display: flex;
    justify-content: space-around;
}

.logo{
    height: 100%;
    width: 100%;
    object-fit: contain;
}




@keyframes moveRight {
    0%{
        transform: translateX(-4rem);
    }

    100%{
        transform: translateX(0);
    }
    
}

@keyframes moveUp {
    0%{
        transform: translateY(4rem);
    }

    100%{
        transform: translateY(0);
    }
    
}