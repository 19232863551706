.ContactForm{
    margin-top: 5rem;
    margin-bottom: 8rem;
    
}

.ContactForm h1{
    text-align: center;
}


.form{
    display: flex;
    flex-direction: column;
    
}

.inputContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.input{
    height: 6rem;
    width: 70%;
    text-align: center;
    font-size: 2rem;
}

.input:focus{
    border: 1px solid #15458C;
    outline: none;
}

.message{
    width: 70%;
    height: 10rem;
    font-size: 2rem;
}


.btnContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.formBtn{
    width: 15%;
    height: 5rem;
    background-color: #15458C;
    color: white;
    text-align: center;
}