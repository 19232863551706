
.aboutHeader{

    background-image: linear-gradient( 
    rgba(14, 30, 54, 0.45),
    rgba(14, 30, 54, 0.45)),
    url(../../media/aboutPic.jpg);

    
    background-position-y: 30%;
    background-size: cover;
    background-repeat: no-repeat; 
    width: 100%;
    height: 45vh;
    padding: 3rem;
   
}

.serviceHeader{

    background-image: linear-gradient( 
    rgba(14, 30, 54, 0.45),
    rgba(14, 30, 54, 0.45)),
    url(../../media/Pipeline.jpg);

    background-size: cover;
     background-position-y: 45%; 
    background-repeat: no-repeat; 
    width: 100%;
    height: 45vh;
    padding: 3rem;
   
}


.projectHeader{

    background-image: linear-gradient( 
    rgba(14, 30, 54, 0.45),
    rgba(14, 30, 54, 0.45)),
    url(../../media/projectPic.jpg);

    background-size: cover;
    background-position-y: 60%;
    background-repeat: no-repeat; 
    width: 100%;
    height: 45vh;
    padding: 3rem;
}

.contactHeader{

    background-image: linear-gradient( 
    rgba(14, 30, 54, 0.45),
    rgba(14, 30, 54, 0.45)),
    url(../../media/contactPic.jpg);

    background-size: cover;
    background-position-y: 80%;
    background-repeat: no-repeat; 
    width: 100%;
    height: 45vh;
    padding: 3rem;
   
}

.galleryHeader{

    background-image: linear-gradient( 
    rgba(14, 30, 54, 0.45),
    rgba(14, 30, 54, 0.45)),
    url(../../media/aboutPic.jpg);

    background-size: cover;
    background-position-y: 50%;
    background-repeat: no-repeat; 
    width: 100%;
    height: 45vh;
    padding: 3rem;
}