.main{
    margin-top: 4rem;
    padding: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}

.heading{
    font-family: 'Ubuntu', sans-serif;
    margin-bottom: 1rem;
    margin-top: 6rem;
    color: #15458C;
    text-align: center;
}

.serviceList{
    font-family: 'Nunito', sans-serif;
}

.inscription{
    margin-bottom: 6rem;
}
.servicePic{
    width: 100%;
}