.Footer{
    display: flex;
    background-color: #242424;
    color: white;
    padding: 4rem;
    text-align: center;
    margin-top: 8rem;
}
@media only screen and (max-width: 50em) {
    .Footer {
      display: block;
    }
  }

.Footer section{
    flex: 1;
}

.Footer h2{
    margin-bottom: 2rem;
    color: white;
    font-family: sans-serif;
}

.menu{
    list-style-type: none;

}

.menu li{
    margin-bottom: 1rem;
}

.menu li > *{
    text-decoration: none;
    color: white;
}

.menu li:hover > *{
    color: #58B8F9;
}

.contactList{
    list-style-type: none;
}

