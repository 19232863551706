.failed{
    color: white;
    height: 15rem;
    width: 20rem;
    position: fixed;
    background-color: red;
    z-index: 50;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding: 3rem;
    font-family: 'Nunito', sans-serif;
    
}
@media only screen and (max-width: 28.44em) {
    .failed {
      font-size: 2rem;
    }
}


.icon{
    font-size: 4rem;
}
@media only screen and (max-width: 31.25em) {
    .icon {
      font-size: 2rem;
    }
}
