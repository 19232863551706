.Testimonies{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem;
    margin-top: 5em;
    
}

.Testimonies h1{
    margin-bottom: 3rem;
}

.Testimonies p{
    margin-bottom: 3rem;
    font-size: 2.5rem;
}

.anim{
    animation: textAppear 2000ms linear;
    
}


@keyframes textAppear {
   0%{
       opacity: 0;
   } 

   100%{
       opacity: 1;
    } 
}