.main{
    margin-top: 4rem;
    padding: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.table, .rowHeading, .rowData{
    border: 2px solid black;
    border-collapse: collapse;
    text-align: center;
}

@media only screen and (max-width: 46.56em) {
    .rowData {
      font-size: 60%;
    }

    .rowHeading{
        font-size: 60%;
      }
}

 @media only screen and (max-width: 27.5em) {
    .rowData {
      font-size: 55%;
    }

    .rowHeading{
        font-size: 55%;
      }
}

@media only screen and (max-width: 25.56em) {
    .rowData {
      font-size: 50%;
    }

    .rowHeading{
        font-size: 50%;
      }
}

@media only screen and (max-width: 23.75em) {
    .rowData {
      font-size: 45%;
    }

    .rowHeading{
        font-size: 45%;
      }
}
