.main{
    margin-top: 9rem;
    
}

.contactHeading{
    margin-bottom: 1rem;
    color: #15458C;;
}

.first{
   display: flex;
   justify-content: space-around;
   align-items: center;

   margin-bottom: 9rem;
}

@media only screen and (max-width: 46.87em) {
    .first {
        flex-direction: column;
        margin-bottom: 4rem;
    }

    .email{
        margin-bottom: 3rem;
        text-align: center;
    }
}

.phy_address{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    padding: 5rem;
}

.mapHeading{
    text-align: center;
    margin-bottom: 3rem;
}