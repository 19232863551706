.Logo{
    color: white;
}

.Logo >*{
    color: white;
    text-decoration: none;
}

.logoImg{
    height: 7rem;
    width: 7rem;
}