*,
*::before,
*::after{
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html{
  font-size: 62.5%;
}
@media only screen and (max-width: 31.25em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 28.44em) {
  html {
    font-size: 35%;
  }
}


body{
  box-sizing: border-box;
  font-size: 18px;
}

h1{
  font-family: 'Ubuntu', cursive;
  font-size: 5rem;
  color: #15458C;
  margin-bottom: 5rem;
  text-align: center;
}

p{
  font-family: 'Nunito', sans-serif;
}

