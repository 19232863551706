.Button{
    width: 20rem;
    padding: 1.5rem;
    outline: none;
    border: 2px solid rgb(14, 30, 54);
    cursor: pointer;
    display: block;
    margin-top: 3rem;
    
}

.Button:hover{
    background-color: rgba(14, 30, 54, 0.45);
    color: white;
    outline: none;
    border: none;
}