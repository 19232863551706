.Label{
    background-color: #15458C;
    color: white;
    position: absolute;
    padding: 3rem;
    width: 40%;
    top: 38%;
    left: 50%;
}

@media only screen and (max-width: 40.94em) {
    .Label{
        width: 80%;
        left: 10%;
    }
}

.wh1{
    color: white;
    margin-bottom: 0;
}