.navigation {
    display: none;
  }
  .navigation__checkbox {
    display: none;
  }
  .navigation__button {
    background-color: #15458C;
    height: 7rem;
    width: 7rem;
    position: fixed;
    top: 4rem;
    right: 6rem;
    border-radius: 50%;
    z-index: 100;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .navigation__background {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-color: #15458C;
    position: fixed;
    top: 4.5rem;
    right: 6.5rem;
    z-index: 30;
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
  }
  .navigation__nav {
    height: 100vh;
    width: 0;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
  }
  .navigation__list {
    list-style: none;
    text-align: center;
    width: 100%;
  }
  .navigation__item {
    margin: 1rem;
  }
  .navigation__link {
    font-size: 3rem;
    color: #fff; /*#333*/
  }
  .navigation__checkbox:checked ~ .navigation__background {
    -webkit-transform: scale(80);
            transform: scale(80);
  }
  .navigation__checkbox:checked ~ .navigation__nav {
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
  .navigation__icon {
    position: relative;
  }
  .navigation__icon, .navigation__icon::before, .navigation__icon::after {
    width: 3rem;
    height: 2px;
   background-color: #fff;/*#333; */
    display: inline-block;
  }
  .navigation__icon::before, .navigation__icon::after {
    content: "";
    position: absolute;
    left: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .navigation__icon::before {
    top: -1rem;
  }
  .navigation__icon::after {
    top: 1rem;
  }
  .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent;
  }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::before {
    top: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  @media only screen and (max-width: 62.5em) {
    .navigation {
      display: block;
    }
  }

  .logoDiv{
    position: absolute;
    left: 3rem;
    top: 3rem;
    z-index: 20;
  }