.Navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: #15458C;
    width: 100%;
    z-index: 50;
    padding-left: 3rem; 
}
@media only screen and (max-width: 62.5em) {
    .Navigation {
      display: none;
    }
}


.logo{
    color: white;
}

.menu_lists{
    display: flex;
    justify-content: space-around;
    list-style-type: none;
}
@media only screen and (max-width: 62.5em) {
    .menu_lists {
        display: none;
    }
  }

.menu_lists li{
    margin-right: 2rem;
}

.Navigation li:hover > *{
    color: #58B8F9;
}

.Navigation li > *{
    text-decoration: none;
    color: white;
    font-size: 2.5rem;
}